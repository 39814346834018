body > * {
    --primary-font-family: 'Open Sans', sans-serif;
    --mono-font-family: 'Roboto', sans-serif;

    --app-black: #000000;
    --app-white: #FFFFFF;
    --app-white-40: rgba(255, 255, 255, 0.4);
    --app-white-30: rgba(255, 255, 255, 0.3);
    --app-bleach: #F0F0F0;
    --app-whiteSmoke: #EAEAEA;
    --app-whiteSmoke-30: rgba(234, 234, 234, 0.3);
    --app-gainsboro: #DFDFDF;
    --app-gainsboro-30: rgba(223, 223, 223, 0.3);
    --app-silver: #BABABA;
    --app-silver-40: rgba(186, 186, 186, 0.4);
    --app-suvaGrey: #959595;
    --app-shuttleGrey: #5D636B;
    --app-mortar: #5B5B5B;
    --app-charcoal: #484848;
    --app-vulcan: #3B3D44;
    --app-bunker: #2B2D32;
    --app-blackRussian: #212227;
    --app-nero: #171717;
    --app-nero-80: rgba(23, 23, 23, 0.8);
    --app-summerSky: #4097E6;
    --app-dodgerBlue: #48A8ff;
    --app-airForceBlue: #6085A6;
    --app-rockBlue: #87A1B9;
    --app-valencia: #D15353;
    --app-puce: #CC8E8E;
    --app-pastelGreen: #73D98B;
    --app-lightGrey: #E7E7E7;

    /** Common Colors **/
    --primary-color: var(--app-white);
    --secondary-color: var(--app-black);
    --warning-color: var(--app-puce);
    --caution-color: rgba(255, 255, 0, 0.4);
    --advisory-color: var(--app-summerSky);
    --accent-color: var(--app-silver);
    --info-color: var(--app-suvaGrey);
    --nominal-color: var(--app-pastelGreen);
    --muted-color: var(--app-shuttleGrey);
    --selected-color: var(--app-dodgerBlue);
    --disabled-color: var(--app-suvaGrey);
    --notification-color: var(--app-valencia);

    --app-background: var(--app-whiteSmoke);

    /* Login Panel */
    --login-panel-background: var(--app-vulcan);
    --login-panel-popup-background: var(--app-vulcan);
    --login-panel-light-popup-background: var(--app-bleach);
    --login-panel-color: var(--app-bleach);
    --login-panel-accent-color: var(--app-airForceBlue);
    --login-panel-info-color: var(--app-whiteSmoke);
    --login-panel-light-info-color: var(--app-suvaGrey);
    --login-panel-dark-bg-color: var(--app-bunker);
    --login-panel-popup-border-color: var(--app-dodgerBlue);

    /* Logout Panel */

    /* Pockets Panel */
    --pockets-panel-bg-color: var(--app-nero);
    --pocket-panel-border-color: var(--app-white);
    --pocket-panel-disclosure-fill: var(--app-whiteSmoke);
    --pocket-panel-disclosure-expanded-fill: var(--app-white);
    --pocket-panel-svg-fill: var(--app-white);
    --pocket-panel-close-svg-fill: var(--app-bleach);

    --pocket-node-dark-color: var(--app-shuttleGrey);
    --pocket-node-dark-background: var(--app-silver);
    --pocket-node-dark-border-color: transparent;
    --pocket-node-dark-hover-color: var(--app-shuttleGrey);
    --pocket-node-dark-hover-background: var(--app-silver);
    --pocket-node-dark-hover-border-color: var(--app-whiteSmoke);
    --pocket-node-dark-selected-color: var(--app-shuttleGrey);
    --pocket-node-dark-selected-background: var(--app-silver);
    --pocket-node-dark-selected-border-color: var(--app-shuttleGrey);

    --pocket-node-dark-button-fill: var(--app-suvaGrey);
    --pocket-node-dark-button-hover-fill: var(--app-white);
    --pocket-node-dark-button-selected-fill: var(--app-bleach);

    --pocket-node-light-color: var(--app-shuttleGrey);
    --pocket-node-light-background: var(--app-bleach);
    --pocket-node-light-hover-color: var(--app-shuttleGrey);
    --pocket-node-light-hover-background: var(--app-white);
    --pocket-node-light-selected-color: var(--app-shuttleGrey);
    --pocket-node-light-selected-background: var(--app-white);

    --pocket-node-light-button-fill: var(--app-blackRussian);
    --pocket-node-light-button-hover-fill: var(--app-blackRussian);

    --pocket-node-report-color: var(--app-airForceBlue);
    --pocket-node-report-background: var(--app-white);
    --pocket-node-report-border-color: var(--app-airForceBlue);

    /* Pocket Manager Panel */
    --pocket-manager-panel-bg-color: var(--app-whiteSmoke);
    --pocket-manager-panel-border-color: var(--app-silver);
    --pocket-manager-panel-header-bg-color: var(--app-silver);
    --pocket-manager-panel-header-icon-fill: var(--app-shuttleGrey);
    --pocket-manager-panel-section-header-bg-color: var(--app-gainsboro);
    --pocket-manager-panel-section-header-title-bg-color: #F5F5F5;
    --pocket-manager-panel-section-header-title-border-color: #707070;
    --pocket-manager-panel-grid-item-color: var(--app-shuttleGrey);
    --pocket-manager-panel-grid-item-fill: var(--app-suvaGrey);
    --pocket-manager-panel-grid-item-selected-color: var(--app-dodgerBlue);
    --pocket-manager-panel-grid-item-selected-fill: var(--app-dodgerBlue);
    --pocket-manager-panel-grid-item-selected-bg-color: #F0F0F0;
    --pocket-manager-panel-pocket-color: #484A4F;
    --pocket-manager-panel-author-icon-bg-color: var(--app-white);
    --pocket-manager-panel-author-icon-color: var(--app-charcoal);
    --pocket-manager-panel-author-icon-border-color: #201C1C;
    --pocket-manager-panel-selected-pocket-author-icon-border-color: #FFB30B;
    --pocket-manager-panel-selected-pocket-author-icon-color: #FFB30B;
    --pocket-manager-panel-collaboration-info-bg-color: var(--app-gainsboro);
    --pocket-manager-panel-icon-available-bg-color: var(--app-dodgerBlue);
    --pocket-manager-panel-icon-in-use-bg-color: #D86C34;

    /* System Banner */
    --system-banner-background: var(--app-nero);
    --system-banner-color: var(--app-whiteSmoke);
    --system-banner-accent-color: var(--app-dodgerBlue);
    --system-banner-secondary-color: var(--app-bleach);

    /* Search Results Panel*/
    --search-results-panel-bg-color: transparent;

    --search-results-card-color: var(--app-suvaGrey);
    --search-results-card-accent-color: var(--app-bunker);/*title*/
    --search-results-card-bg-color: transparent;
    --search-results-card-border-color: var(--app-airForceBlue);
    --search-results-card-hover-color: var(--app-suvaGrey);
    --search-results-card-hover-accent-color: var(--app-black);
    --search-results-card-hover-bg-color: var(--app-white-30);
    --search-results-card-hover-border-color: var(--app-summerSky);
    --search-results-card-selected-color: var(--app-black);
    --search-results-card-selected-accent-color: var(--app-dodgerBlue);
    --search-results-card-selected-bg-color: var(--app-white-30);
    --search-results-card-selected-border-color: var(--app-dodgerBlue);
    --search-results-card-disabled-color: var(--app-suvaGrey);
    --search-results-card-disabled-accent-color: var(--app-suvaGrey);
    --search-results-card-disabled-bg-color: var(--app-silver);
    --search-results-card-disabled-border-color: var(--app-suvaGrey);
    --search-results-card-deleted-color: var(--app-suvaGrey);
    --search-results-card-deleted-accent-color: var(--app-suvaGrey);
    --search-results-card-deleted-bg-color: var(--app-silver);
    --search-results-card-deleted-border-color: var(--app-suvaGrey);

    --search-results-table-header-color: var(--app-whiteSmoke);
    --search-results-table-header-bg-color: var(--app-shuttleGrey);
    --search-results-table-color: var(--app-shuttleGrey);
    --search-results-table-bg-color: transparent;
    --search-results-table-hover-color: var(--app-shuttleGrey);
    --search-results-table-hover-bg-color: var(--app-white);
    --search-results-table-selected-color: var(--app-black);
    --search-results-table-selected-bg-color: var(--app-white);

    --search-results-list-bg-color: transparent;
    --search-results-list-color: var(--app-suvaGrey);
    --search-results-list-accent-color: var(--app-shuttleGrey);
    --search-results-list-info-color: var(--app-airForceBlue);
    --search-results-list-selected-info-color: var(--app-dodgerBlue);
    --search-results-list-info-accent-color: var(--app-suvaGrey);
    --search-results-list-hover-bg-color: var(--app-white);
    --search-results-list-hover-color: var(--app-shuttleGrey);
    --search-results-list-hover-accent-color: var(--app-bunker);
    --search-results-list-selected-bg-color: var(--app-white);
    --search-results-list-selected-color: var(--app-black);
    --search-results-list-selected-accent-color: var(--app-black);

    /* Document Panel */
    --document-panel-bg-color: var(--app-nero);
    --document-panel-pagination-button-bg-color: var(--app-bunker);
    --document-panel-pagination-button-fill-color: var(--app-suvaGrey);
    --document-panel-modal-bg-color: var(--app-vulcan);
    --document-panel-modal-color: var(--app-whiteSmoke);
    --document-panel-modal-footer-bg-color: var(--app-summerSky);
    --document-panel-loading-bg-color: var(--app-nero-80);
    --document-panel-loading-color: var(--app-bleach);
    --document-panel-highlight-bg-color: var(--caution-color);
    --document-panel-pdf-color: var(--app-suvaGrey);
    --document-panel-pdf-icon-secondary-color: var(--app-bleach);
    --document-panel-context-menu-color: var(--app-nero);
    --document-panel-context-menu-text-area-color: var(--app-suvaGrey);
    --documnent-panel-context-menu-text-area-background: var(--app-whiteSmoke);
    --document-panel-highlight-button-fill: var(--app-vulcan);
    --document-panel-highlight-button-background: var(--app-white);
    --document-panel-highlight-button-border-color: var(--app-black);

    /* Old Document Panel */
    --old-document-panel-bg-color: var(--app-blackRussian);
    --old-document-panel-pdf-bg-color: var(--app-nero);
    --old-document-panel-color: var(--app-bleach);
    --old-document-panel-secondary-color: var(--app-whiteSmoke);
    --old-dcoument-panel-accent-color: var(--app-suvaGrey);
    --old-document-panel-button-bg-color: transparent;
    --old-document-panel-button-border-color: var(--primary-color);
    --old-document-panel-pdf-icon-color: var(--app-shuttleGrey);
    --old-document-panel-pdf-color: var(--app-suvaGrey);
    --old-document-panel-pdf-icon-secondary-color: var(--app-bleach);
    --old-document-panel-context-menu-color: var(--app-nero);
    --old-document-panel-context-menu-text-area-color: var(--app-suvaGrey);
    --old-documnent-panel-context-menu-text-area-background: var(--app-whiteSmoke);
    --old-document-panel-highlight-button-fill: var(--app-vulcan);
    --old-document-panel-highlight-button-background: var(--app-white);
    --old-document-panel-highlight-button-border-color: var(--app-black);
    --old-document-panel-required-field-color: #DE6D49;

    /* System Toolbar */
    --system-toolbar-background: var(--app-blackRussian);

    /* System Tools Panel */
    --system-tool-panel-background: transparent;
    --system-tool-header-background: var(--app-gainsboro);
    --system-tool-header-color: var(--app-shuttleGrey);

    /* Tags Panel */
    --tags-panel-header-background: var(--app-shuttleGrey);
    --tags-panel-header-color: var(--app-whiteSmoke);
    --tags-panel-tag-color: var(--app-silver);

    /* User Management */
    --profile-panel-container-bg-color: #E4E4E4;
    --profile-panel-card-header-color: var(--app-shuttleGrey);
    --profile-panel-card-header-bg-color: var(--app-gainsboro);
    --profile-panel-card-header-border-color: var(--app-gainsboro);
    --profile-panel-card-header-icon-color: var(--app-charcoal);
    --profile-panel-card-header-icon-bg-color: var(--app-white);
    --profile-panel-user-request-card-header-selected-color: var(--app-dodgerBlue);
    --profile-panel-user-request-card-header-selected-bg-color: var(--app-white);
    --profile-panel-user-request-card-header-selected-border-color: var(--app-summerSky);
    --profile-panel-user-request-card-header-selected-icon-bg-color: var(--app-gainsboro);
    --profile-panel-card-color: var(--app-shuttleGrey);

    /* Upload Panel */
    --pending-item-bg-color: var(--app-bleach);
    --pending-item-color: var(--app-bunker);
    --pending-item-border-color: var(--app-airForceBlue);
    --pending-item-hover-bg-color: var(--app-white);
    --pending-item-hover-color: var(--app-bunker);
    --pending-item-hover-border-color: var(--app-summerSky);
    --pending-item-loading-bg-color: var(--app-bleach);
    --pending-item-loading-color: var(--app-silver);
    --pending-item-loading-border-color: var(--app-airForceBlue);
    --pending-item-selected-bg-color: var(--app-white);
    --pending-item-selected-bg-secondary-color: var(--app-bleach);
    --pending-item-selected-color: var(--app-black);
    --pending-item-selected-border-color: var(--app-dodgerBlue);
    --pending-item-disabled-bg-color: var(--app-silver);
    --pending-item-disabled-color: var(--app-suvaGrey);
    --pending-item-disabled-border-color: var(--app-suvaGrey);
    --pending-item-deleted-bg-color: var(--app-silver);
    --pending-item-deleted-color: var(--app-bleach);
    --pending-item-deleted-border-color: transparent;
    --pending-item-accept-button-color: var(--app-dodgerBlue);
    --pending-item-reject-button-color: var(--app-airForceBlue);
    --pending-item-button-bg-color: transparent;
    --pending-item-loading-button-color: var(--app-airForceBlue);
    --pending-item-button-hover-bg-color: var(--app-black);

    /* Report Panel */
    --report-panel-bg-color: var(--app-blackRussian);
    --report-panel-color: var(--app-bleach);
    --report-panel-secondary-color: var(--app-whiteSmoke);
    --report-panel-preview-bg-color: var(--app-nero);
    --report-panel-popup-color: #484A4F;

    /* Settings Panel */
    --settings-panel-border-color: var(--app-dodgerBlue);
    --settings-panel-bg-color: var(--app-nero);
    --settings-panel-header-icon-color: var(--app-charcoal);
    --settings-panel-header-icon-bg-color: var(--app-white);
    --settings-panel-header-name-color: #D3D3D3;
    --settings-panel-header-role-color: var(--app-suvaGrey);
    --settings-panel-section-header-bg-color: var(--app-vulcan);
    --settings-panel-userinfo-bg-color: #222227;
    --settings-panel-userinfo-fields-text-color: var(--app-suvaGrey);
    --settings-panel-userinfo-info-text-color: var(--app-shuttleGrey);
    --settings-panel-svg-grey: #B5B5B5;
    --settings-panel-logout-pink: #D2A4A4;

    /* Widgets */

    /* Auto Complete */
    --auto-complete-bg-color: var(--app-vulcan);
    --auto-complete-color: var(--app-whiteSmoke);

    /* Buttons */
    --button-color: var(--app-silver);
    --button-bg-color: var(--app-vulcan);
    --button-hover-color: var(--app-white);
    --button-hover-bg-color: var(--app-black);
    /*--button-armed-color: ;*/
    /*--button-armed-bg-color: ;*/
    --button-disabled-color: rgba(186, 186, 186, 0.4);/*--app-silver*/
    --button-disabled-bg-color: var(--app-bunker);
    --button-highlight-color: var(--app-bleach);
    --button-highlight-bg-color: var(--app-shuttleGrey);

    /*--light-button-color: var(--app-white);*/
    /*--light-button-bg-color: var(--app-summerSky);*/
    --light-button-color: var(--app-summerSky);
    --light-button-bg-color: var(--app-white);
    --light-button-hover-color: var(--app-white);
    --light-button-hover-bg-color: var(--app-dodgerBlue);
    --light-button-highlight-color: var(--app-vulcan);
    --light-button-highlight-bg-color: transparent;
    /*--button-armed-color: ;*/
    /*--button-armed-bg-color: ;*/
    /*--button-disabled-color: ;*/
    /*--button-disabled-bg-color: ;*/

    /* Combo Box */
    --combo-box-color: var(--app-silver);
    --combo-box-bg-color: var(--app-vulcan);
    --combo-box-arrow-color: var(--app-suvaGrey);
    --combo-box-arrow-bg-color: var(--app-bunker);
    --combo-box-hover-color: var(--app-whiteSmoke);
    --combo-box-arrow-hover-color: var(--app-whiteSmoke);
    --combo-box-arrow-hover-bg-color: var(--app-bunker);
    --combo-box-arrow-hover-border-color: var(--app-shuttleGrey);
    --combo-box-hover-bg-color: repeating-linear-gradient(135deg, var(--app-blackRussian), var(--app-blackRussian) 1rem, var(--app-mortar) 0.1rem, var(--app-mortar) 1.1rem);
    --combo-box-border-color: var(--app-shuttleGrey);
    --combo-box-selected-color: var(--app-white);
    --combo-box-selected-bg-color: var(--app-shuttleGrey);
    --combo-box-arrow-selected-color: var(--app-silver);
    --combo-box-arrow-selected-bg-color: var(--app-bunker);
    --combo-box-arrow-selected-border-color: var(--app-shuttleGrey);
    --combo-box-disabled-color: --app-silver-40;
    --combo-box-disabled-bg-color: var(--app-vulcan);
    --combo-box-list-color: var(--app-suvaGrey);
    --combo-box-list-bg-color: var(--app-blackRussian);
    --combo-box-list-hover-color: var(--app-whiteSmoke);
    --combo-box-list-hover-bg-color: var(--app-bunker);
    --combo-box-list-divider-border-color: #707070;

    --light-combo-box-color: var(--app-shuttleGrey);
    --light-combo-box-bg-color: var(--app-bleach);
    --light-combo-box-arrow-color: var(--app-suvaGrey);
    --light-combo-box-hover-color: var(--app-black);
    --light-combo-box-arrow-hover-color: var(--app-black);
    --light-combo-box-hover-bg-color: var(--app-silver);
    --light-combo-box-selected-color: var(--app-airForceBlue);
    --light-combo-box-selected-bg-color: var(--app-white);
    --light-combo-box-arrow-selected-bg-color: var(--app-suvaGrey);
    --light-combo-box-list-color: var(--app-suvaGrey);
    --light-combo-box-list-bg-color: var(--app-vulcan);
    --light-combo-box-list-selected-color: var(--app-dodgerBlue);
    --light-combo-box-arrow-bkg-color: var(--app-bunker);

    /* Card */
    --card-bg-color: var(--app-lightGrey);

    /* Check Box */
    --check-box-border-color: var(--app-silver);
    --check-box-hover-border-color: var(--app-summerSky);
    --check-box-selected-border-color: var(--app-airForceBlue);
    --check-box-checkmark-color: var(--app-summerSky);
    --check-box-disabled-border-color: var(--app-suvaGrey);
    --check-box-light-border-color: var(--primary-color);
    --check-box-light-checkmark-color: var(--primary-color);

    /* Global Switch Button */
    --global-switch-button-bg-color: var(--app-vulcan);
    --global-switch-button-icon-color: var(--app-shuttleGrey);
    --global-switch-button-selected-bg-color: var(--app-shuttleGrey);
    --global-switch-button-selected-icon-color: var(--app-silver);

    --light-global-switch-button-bg-color: var(--app-whiteSmoke);
    --light-global-switch-button-icon-color: var(--app-silver);
    --light-global-switch-button-selected-bg-color: var(--app-dodgerBlue);
    --light-global-switch-button-selected-icon-color: var(--app-white);

    /* File Input*/
    --file-input-bg-color: #F0F0F0;
    --file-input-border-color: var(--app-silver);
    --file-input-color: var(--app-suvaGrey);
    --file-input-link-color: var(--app-airForceBlue);
    --file-input-icon-color: var(--app-shuttleGrey);
    --file-input-hover-bg-color: var(--app-blackRussian);
    --file-input-hover-border-color: var(--app-silver);
    --file-input-hover-color: var(--app-suvaGrey);
    --file-input-hover-link-color: var(--app-white);
    --file-input-hover-icon-color: var(--app-white);

    /* Loading Indicator */
    --loading-indicator-background-color: var(--app-white);
    --loading-indicator-border-color: var(--app-airForceBlue);

    /* Menu */
    --menu-bg-color: var(--app-blackRussian);
    --menu-button-color: var(--app-white);
    --menu-button-hover-color: var(--app-white);
    --menu-modal-bg-color: var(--app-vulcan);
    --menu-modal-button-color: var(--app-whiteSmoke);
    --menu-modal-button-hover-bg-color: var(--app-shuttleGrey);
    --menu-modal-button-active-color: var(--app-white);
    --menu-modal-button-disabled-color: var(--app-whiteSmoke-30);
    --menu-button-disabled-bg-color: var(--app-white-40);
    --menu-button-hover-border-color: var(--app-white);
    --menu-button-selected-border-color: var(--app-dodgerBlue);
    --menu-light-bg-color: var(--app-gainsboro);
    --menu-light-border-color: var(--app-shuttleGrey);
    --menu-light-button-color: var(--app-vulcan);
    --menu-light-button-hover-color: var(--app-white);
    --menu-light-modal-bg-color: var(--app-vulcan);
    --menu-light-modal-button-color: var(--app-whiteSmoke);
    --menu-light-modal-button-hover-bg-color: var(--app-shuttleGrey);
    --menu-light-modal-button-hover-border-color: var(--app-dodgerBlue);
    --menu-light-modal-button-active-color: var(--app-whiteSmoke-30);
    --menu-light-modal-button-disabled-color: var(--app-shuttleGrey);
    --menu-light-button-disabled-bg-color: var(--app-white-40);
    --menu-light-button-hover-border-color: var(--app-white);
    --menu-light-button-selected-border-color: var(--app-dodgerBlue);

    /* Progress Bar*/
    --progress-bar-track-background: var(--app-airForceBlue);
    --progress-bar-charged-background: var(--app-dodgerBlue);

    /* Popup */
    --popup-color: var(--app-vulcan);
    --popup-bg-color: var(--app-silver);

    /* Scrollbar */
    --scrollbar-thumb-color: var(--app-shuttleGrey);
    --scrollbar-track-color: var(--app-silver);

    /* Search Box */
    --search-box-color: var(--app-whiteSmoke);
    --search-box-placeholder-color: var(--app-suvaGrey);
    --search-box-bg-color: transparent;
    --search-box-border-color: var(--app-suvaGrey);
    --search-box-selected-color: var(--app-whiteSmoke);
    --search-box-selected-bg-color: transparent;
    --light-search-box-color: var(--app-shuttleGrey);
    --light-search-box-bg-color: var(--app-bleach);
    --light-search-box-selected-color: var(--app-airForceBlue);
    --light-search-box-selected-bg-color: var(--app-white);

    /* Stats */
    /* amcharts */
    --stats-background: var(--app-bleach);
    --stats-color: var(--app-silver);
    --xy-chart-header-background: #8DC77B;
    --xy-chart-header-color: var(--app-bleach);
    --word-cloud-header-background: #E8BA54;
    --word-cloud-header-color: var(--app-bleach);
    --variable-radius-pie-chart-header-background: var(--app-dodgerBlue);
    --variable-radius-pie-chart-header-color: var(--app-bleach);
    --draggable-pie-chart-header-background: #EC1889;
    --draggable-pie-chart-header-color: var(--app-bleach);
    --donut-pie-chart-header-background: #7654E8;
    --donut-pie-chart-header-color: var(--app-bleach);

    /* Switch Button */
    --switch-button-track-color: var(--app-shuttleGrey);
    --switch-button-thumb-color: var(--app-airForceBlue);

    /* Tag */
    --tag-bg-color: var(--app-summerSky);
    --tag-color: var(--app-white);
    --tag-border-color: var(--app-silver);
    --tag-edit-color: var(--app-suvaGrey);
    --tag-edit-bg-color: transparent;
    --tag-edit-border-color: var(--app-suvaGrey);
    --tag-recommended-bg-color: repeating-linear-gradient(135deg, #308DE2, #308DE2 0.5rem, #1E548A 0.5rem, #1E548A 0.9rem);

    /* Text Edit */
    --text-field-color: var(--app-silver);
    --text-field-bg-color: transparent;
    --text-field-border-color: transparent;
    --text-field-placeholder-color: var(--app-suvaGrey);
    /*--text-field-hover-color: ;*/
    /*--text-field-hover-bg-color: ;*/
    /*--text-field-hover-border-color: ;*/
    --text-field-selected-color: var(--app-whiteSmoke);
    --text-field-selected-bg-color: var(--app-vulcan);
    --text-field-selected-border-color: var(--app-silver);
    --text-field-disabled-color: var(--app-suvaGrey);
    --text-field-disabled-bg-color: transparent;
    --text-field-disabled-border-color: transparent;
    --text-field-dirty-color: var(--app-suvaGrey);
    --text-field-dirty-bg-color: transparent;
    --text-field-dirty-border-color: var(--app-silver);
    --text-field-edit-color: var(--app-suvaGrey);
    --text-field-edit-bg-color: transparent;
    --text-field-edit-border-color: var(--app-silver);

    --text-area-background: var(--app-vulcan);
    --text-area-color: var(--app-suvaGrey);
    --text-area-placeholder-color: var(--app-suvaGrey);

    /* Toggle Button */
    --toggle-button-color: var(--app-shuttleGrey);
    --toggle-button-bg-color: transparent;
    --toggle-button-hover-color: var(--app-white);
    --toggle-button-hover-bg-color: var(--app-black);
    --toggle-button-selected-color: var(--app-bleach);
    --toggle-button-selected-bg-color: var(--app-bunker);
    /*--toggle-button-armed-color: ;*/
    /*--toggle-button-armed-bg-color: ;*/
    /*--toggle-button-disabled-color: ;*/
    /*--toggle-button-disabled-bg-color: ;*/

    --light-toggle-button-color: var(--app-shuttleGrey);
    --light-toggle-button-bg-color: transparent;
    --light-toggle-button-hover-color: var(--app-black);
    --light-toggle-button-hover-bg-color: var(--app-silver);
    --light-toggle-button-selected-color: var(--app-airForceBlue);
    --light-toggle-button-selected-bg-color: var(--app-white);
    /*--light-toggle-button-armed-color: ;*/
    /*--light-toggle-button-armed-bg-color: ;*/
    /*--light-toggle-button-disabled-color: ;*/
    /*--light-toggle-button-disabled-bg-color: ;*/

    /* Tooltip */
    --tooltip-color: var(--app-whiteSmoke);
    --light-tooltip-color: var(--app-bunker);
    --tooltip-bg-color: var(--app-vulcan);
    --light-tooltip-bg-color: var(--app-white);

    /* TreeView */
    /*--tree-view-connector: 0.1rem dashed #154f70;*/
    /*--tree-view-disclosure-background: #154f70;*/
    /*--tree-view-disclosure-hover-background: #91b5cf;*/
    /*--tree-view-primary-color: var(--app-whiteSmoke);*/
    /*--tree-view-secondary-color: var(--app-suvaGrey);*/
    /*--tree-view-content-first-child-background: var(--app-black);*/
    /*--tree-view-content-first-child-selected-background: var(--app-blackRussian);*/
    /*--tree-view-content-first-child-selected-border: var(--app-silver);*/
    /*--tree-view-content-first-child-hover-background: var(--app-black);*/
    /*--tree-view-content-background: var(--app-bleach);*/
    /*--tree-view-content-color: var(--app-bunker);*/
    /*--tree-view-content-selected-background: var(--app-white);*/
    /*--tree-view-content-hover-background: var(--app-white);*/

    /* User Input */
    --user-input-button-color: var(--app-charcoal);
    --user-input-expanded-button-color: var(--app-suvaGrey);
    --user-input-expanded-button-bg-color: var(--app-bunker) !important;
    --user-input-expanded-text-edit-bg-color: var(--app-vulcan);
}

::-webkit-scrollbar {
    height: 0.8rem;
    width: 0.8rem;
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: var(--scrollbar-thumb-color);
}

::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background-color: var(--scrollbar-track-color);
}
