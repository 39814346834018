.tag {
    background: var(--tag-bg-color);
    height: 2.55rem;
}

.tag.global {
    /*border: 0.2rem solid var(--tag-border-color);*/
}

.tag.edit {
    background: var(--tag-edit-bg-color);
    border: 0.2rem solid var(--tag-edit-border-color);
}

.tag.recommended {
    background: var(--tag-recommended-bg-color);
}

.tag.recommended:hover {
    background: var(--tag-bg-color);
}


.tag .tag-text {
    color: var(--tag-color);
}

.tag .text-edit {
    min-width: 10.5rem;
    min-height: 2.15rem;
    height: 2.15rem;
}

.tag .text-edit input[type=text] {
    /*border-bottom: none;*/
    /*background: var(--tag-edit-bg-color);*/
    /*color: var(--tag-edit-color);*/
}

.tag .text-edit input[type=text]::placeholder {
    /*color: var(--tag-edit-color);*/
}

.tag .text-edit input[type=text]:focus {
    /*border-bottom: none;*/
    /*background: var(--tag-edit-bg-color);*/
    /*color: var(--tag-edit-color);*/
}

.tag .delete-btn {
    /*opacity: 0;*/
    /*width: 0;*/
    fill: var(--tag-color);
}

/*.tag:hover .delete-btn {*/
/*    width: 100%;*/
/*    opacity: 1;*/
/*}*/

.tag .overlay {
    /*display: none;*/
    opacity: 0;
    pointer-events: none;
    top: 0;
    left: 0;
}

.tag:hover .tag-body {
    opacity: 0.3;
}

.tag.readonly:hover .tag-body,
.tag.edit:hover .tag-body {
    opacity: 1;
}

.tag:hover .overlay {
    /*display: flex;*/
    opacity: 1;
    pointer-events: auto;
    flex: 1 1 auto;
}
