.menu {
    background: var(--menu-bg-color);
}

.menu.light {
    background: var(--menu-light-bg-color);
    border: 0.1rem solid var(--menu-light-border-color);
}

.menu .menu-item:hover .menu-modal {
    display: block;
}

.menu .menu-item .menu-modal {
    display: none;
    position: absolute;
    z-index: 9;
    background: var(--menu-modal-bg-color);
    width: 17rem;
}

.menu.light .menu-item .menu-modal {
    background: var(--menu-light-modal-bg-color);
}

.menu .menu-item .menu-modal .button {
    background: transparent;
    color: var(--menu-modal-button-color);
}

.menu.light .menu-item .menu-modal .button {
    color: var(--menu-light-modal-button-color);
    border: 0.1rem solid transparent;
}

.menu .menu-item .menu-modal .button:hover {
    background: var(--menu-modal-button-hover-bg-color);
}

.menu.light .menu-item .menu-modal .button:hover {
    background: var(--menu-light-modal-button-hover-bg-color);
    border: 0.1rem solid var(--menu-light-modal-button-hover-border-color);
}

.menu .menu-item .menu-modal .button:active {
    background: var(--menu-modal-button-hover-bg-color);
    color: var(--menu-modal-button-active-color);
}

.menu.light .menu-item .menu-modal .button:active {
    background: var(--menu-light-modal-button-hover-bg-color);
    color: var(--menu-light-modal-button-active-color);
}

.menu .menu-item .menu-modal .button.disabled {
    background: transparent;
    color: var(--menu-modal-button-disabled-color);
}

.menu.light .menu-item .menu-modal .button.disabled {
    color: var(--menu-light-modal-button-disabled-color);
}

.menu .pdf-viewer .header .menu-item {
    /*margin-top: 1rem;*/
}

.menu .menu-item .menu-button {
    color: var(--menu-button-color);
    background: transparent;
    width: 5.9rem;
}

.menu.light .menu-item .menu-button {
    color: var(--menu-light-button-color);
}

.menu .menu-item .menu-button:hover {
    color: var(--menu-button-hover-color);
}

.menu.light .menu-item .menu-button:hover {
    color: var(--menu-light-button-hover-color);
}

.menu .menu-item .menu-button.disabled {
    color: var(--menu-button-disabled-bg-color);
}

.menu.light .menu-item .menu-button.disabled {
    color: var(--menu-light-button-disabled-bg-color);
}

.menu .menu-item .menu-button .bar {
    opacity: 0;
    background: var(--menu-button-hover-border-color);
    height: 0.2rem;
    width: 3.4rem;
}

.menu.light .menu-item .menu-button .bar {
    background: var(--menu-light-button-hover-border-color);
}

.menu .menu-item .menu-button:hover .bar {
    opacity: 1;
}

.menu .menu-item .menu-button.selected .bar {
    opacity: 1;
    background: var(--menu-button-selected-border-color);
}


.menu.light .menu-item .menu-button.selected .bar {
    background: var(--menu-light-button-selected-border-color);
}
