.track-vertical {
    top: 2px;
    bottom: 2px;
    right: 0;
    border-radius: 5px;
    background: var(--scrollbar-track-color);
    padding-right: 8px;
}

.track-vertical .thumb-vertical {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: var(--scrollbar-thumb-color);
    padding-right: 8px;
}

.track-horizontal {
    position: absolute;
    /*height: 8px;*/
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 5px;
    background: var(--scrollbar-track-color);
    padding-bottom: 8px;
}

.track-horizontal .thumb-horizontal {
    position: relative;
    display: block;
    height: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: var(--scrollbar-thumb-color);
    padding-bottom: 8px;
}

.view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    margin-right: -15px;
    margin-bottom: -17px !important;
}
