.search-banner {
    background: var(--app-silver);
    gap: 0.25rem;
}

.search-banner .combo-box {
    min-width: 15rem;
    min-height: 2.8rem;
}

.search-banner .combo-box.sort {
    background: var(--app-silver) !important;
}

.search-banner .combo-box.light,
.search-banner .combo-box.light.selected {
    color: var(--light-combo-box-color);
    background: var(--light-combo-box-bg-color);
}

.search-banner .combo-box.light .combo-box-arrow-container,
.search-banner .combo-box.light:hover .combo-box-arrow-container,
.search-banner .combo-box.light.selected .combo-box-arrow-container {
    background: transparent;
    border: none;
}

.search-banner .combo-box.light .combo-box-arrow,
.search-banner .combo-box.light.selected .combo-box-arrow {
    fill: var(--app-bunker);
}

.search-banner .button {
    background: var(--light-toggle-button-bg-color);
    fill: var(--light-toggle-button-color);
    color: var(--light-toggle-button-color);
}

.search-banner .button:hover {
    background: var(--light-toggle-button-hover-bg-color);
    fill: var(--light-toggle-button-hover-color);
    color: var(--light-toggle-button-hover-color);
}

.search-banner .button.selected {
    background: var(--light-toggle-button-selected-bg-color);
    fill: var(--light-toggle-button-selected-color);
    color: var(--light-toggle-button-selected-color);
}

.search-banner .combo-box-button {
    min-width: 10rem;
    background: var(--light-combo-box-bg-color);
    color: var(--light-combo-box-color);
    min-height: 2.8rem;
}

.search-banner .combo-box-button .combo-box-arrow {
    fill: var(--app-bunker);
    opacity: 0.7;
    animation: rotationCounterClockwiseComboBoxButton 0.25s 1 linear;
}

.search-banner .combo-box-button.selected .combo-box-arrow {
    animation: rotationClockwiseComboBoxButton 0.25s 1 linear;
}

.search-banner .combo-box-button:hover {
    color: var(--light-combo-box-hover-color);
    background: var(--light-combo-box-hover-bg-color);
}

/*.portal .portal {*/
/*    top: 1rem;*/
/*}*/

.portal .portal .advanced {
    background: var(--light-combo-box-list-bg-color);
    color: var(--app-airForceBlue) !important;
    font-size: 1.5rem;
}

.portal .portal .advanced .footer {
    background: var(--app-summerSky);
}

@keyframes rotationClockwiseComboBoxButton {
    from {
        transform: rotate(-180deg);
    }
    to {
        transform: rotate(0deg);
    }
}

@keyframes rotationCounterClockwiseComboBoxButton {
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0deg);
    }
}
