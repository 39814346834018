.search-graphs-panel {
    background-color: var(--app-background);
}

.search-graphs-panel .graphs-grid-container .d-grid {
    grid-column-gap: 1.0rem;
    grid-row-gap: 1.0rem;
    grid-template-columns: repeat(10, minmax(36rem, 1fr));
    grid-template-rows: repeat(1, minmax(32.22rem, 32.22rem));
}

.search-graphs-panel .graphs-grid-container.expanded .d-grid {
    grid-template-columns: repeat(5, minmax(32.22rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(32.22rem, 32.22rem));
}

.d-grid .span-2 {
    grid-column: span 2; /* span from grid column line 1 to 3 (i.e., span 2 columns) */
}

.d-grid .span-3 {
    grid-column: span 3;
}

.d-grid .span-4 {
    grid-column: span 4;
}

.d-grid .span-5 {
    grid-column: span 5;
}

.search-graphs-panel .graphs-grid-container {
    width: auto !important;
    min-height: 32.22rem;
    max-height: 32.22rem;
    /*transition: all 300ms ease-out;*/
    /*align-content: start;*/
}

.search-graphs-panel .graphs-grid-container.expanded {
    min-height: 72rem;
    max-height: 72rem;
    transition: all 300ms ease-out;
}

@media only screen and (min-width:2000px) {
    .search-graphs-panel .graphs-grid-container.expanded {
        min-height: 100rem;
        max-height: 100rem;
        transition: all 300ms ease-out;
    }
}

.search-graphs-panel .toggle-view-btn {
    max-width: 15rem;
    margin-left: 10rem;
}
