.tooltip-portal {
    margin-top: 0.5rem;
    background: var(--tooltip-bg-color);
    color: var(--tooltip-color);
}

.tooltip-portal.top {
    margin-top: -8rem;
}

.tooltip-portal.light {
    background: var(--light-tooltip-bg-color);
    color: var(--light-tooltip-color);
}

.tooltip-portal.portal::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--tooltip-bg-color) transparent;
}

.tooltip-portal.top.portal::after {
    top: 100%;
    left: 1.75rem;
    border-color: var(--tooltip-bg-color) transparent transparent transparent;
}

.tooltip-portal.light.portal::after {
    border-color: transparent transparent var(--light-tooltip-bg-color) transparent;
}

.tooltip-portal.light.top.portal::after {
    border-color: var(--light-tooltip-bg-color) transparent transparent transparent;
}
