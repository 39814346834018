.profile-panel {

}

.profile-panel .search-bar {
    background-color: var(--profile-panel-card-header-bg-color);
}

.profile-panel .profile-info-container,
.profile-panel .user-request-container {
    /*background: var(--profile-panel-container-bg-color);*/
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(46rem, 1fr));
    grid-template-rows: repeat(auto-fill, 5.1rem);
    grid-auto-rows: 1fr;
    grid-gap: 1.2rem 1.2rem;
}

/*.profile-panel .profile-info-container > *,*/
/*.profile-panel .user-request-container > * {*/
/*    flex: 0 1 auto;*/
/*    margin: 1rem;*/
/*    min-width: 46rem;*/
/*    max-width: 50%;*/
/*}*/

.profile-panel .profile-info-container .profile-info .profile-info-header,
.profile-panel .user-request-container .user-request .user-request-header,
.profile-panel .profile-info-container .new-user .new-user-header {
    background: var(--profile-panel-card-header-bg-color);
    color: var(--profile-panel-card-header-color);
    height: 5.1rem;
    border: 0.15rem solid var(--profile-panel-card-header-border-color);
}

.profile-panel .user-request-container .user-request.selected .user-request-header {
    color: var(--profile-panel-user-request-card-header-selected-color);
    background: var(--profile-panel-user-request-card-header-selected-bg-color);
    border: 0.15rem solid var(--profile-panel-user-request-card-header-selected-border-color);
}

.profile-panel .profile-info-container .profile-info .profile-info-header .icon,
.profile-panel .user-request-container .user-request .user-request-header .icon {
    width: 3rem;
    height: 3rem;
    color: var(--profile-panel-card-header-icon-color);
    background: var(--profile-panel-card-header-icon-bg-color);
}

.profile-panel .user-request-container .user-request.selected .user-request-header .icon {
    background: var(--profile-panel-user-request-card-header-selected-icon-bg-color);
}

.profile-panel .profile-info-container .profile-info .profile-info-body,
.profile-panel .profile-info-container .new-user .new-user-body,
.profile-panel .user-request-container .user-request .user-request-body {
    background: var(--profile-panel-container-bg-color);
    /*width: 46rem;*/
    z-index: 3;
    width: inherit;
}

/*.profile-panel .user-request-container .user-request .card-body.expanded {*/
/*    !*max-height: 11.2rem;*!*/
/*}*/

.profile-panel .profile-info-container .profile-info .profile-info-body .profile-info-grid {
    display: grid;
    grid-template-rows: repeat(8, 3.5rem);
    grid-template-columns: 12rem 19rem;
    grid-gap: 0 3rem;
    grid-auto-flow: column;
    color: var(--profile-panel-card-color);
}

.profile-panel .profile-info-container .profile-info .profile-info-body .current-user-grid,
.profile-panel .profile-info-container .new-user .new-user-body .new-user-grid {
    display: grid;
    grid-template-rows: repeat(7, 3.5rem);
    grid-template-columns: 12rem 19rem;
    grid-gap: 0 3rem;
    grid-auto-flow: column;
    color: var(--profile-panel-card-color);
}

.profile-panel .profile-info-container .new-user .new-user-body .new-user-grid .text-area {
    min-width: 42.8rem;
    min-height: 8rem;
}

.profile-panel .profile-info-container .new-user .new-user-body .new-user-grid .text-area textarea {
    background: transparent;
}

.profile-panel .profile-info-container .new-user .new-user-body .new-user-grid .text-area textarea:focus {
    background: var(--text-area-background);
}

.profile-panel .user-request-container .user-request .user-request-body .user-request-grid {
    display: grid;
    grid-template-rows: repeat(2, 3.5rem);
    grid-template-columns: 12rem 19rem;
    grid-gap: 0 3rem;
    grid-auto-flow: column;
    color: var(--profile-panel-card-color);
}

.profile-panel .position-sticky {
    top: 0;
    z-index: 2;
}

/* Styling for combo box button */

.profile-panel .combo-box-button {
    min-width: 10rem;
    background: var(--light-combo-box-bg-color);
    color: var(--light-combo-box-color);
    min-height: 2.8rem;
}

.profile-panel .combo-box-button svg {
    fill: var(--light-combo-box-arrow-color);
    animation: rotationCounterClockwise 0.25s 1 linear;
    transform: rotate(0deg);
}

.profile-panel .combo-box-button:hover {
    color: var(--light-combo-box-hover-color);
    background: var(--light-combo-box-hover-bg-color);
}

.profile-panel .combo-box-button:hover svg {
    fill: var(--light-combo-box-arrow-hover-color);
}

.profile-panel .combo-box-button.selected {
    color: var(--light-combo-box-selected-color);
    background: var(--light-combo-box-selected-bg-color);
}

.profile-panel .combo-box-button.selected svg {
    fill: var(--light-combo-box-arrow-selected-bg-color);
    animation: rotationClockwise 0.25s 1 linear;
    transform: rotate(180deg);
}
