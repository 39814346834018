.combo-box {
    height: 2.8rem;
    color: var(--combo-box-color);
    background: var(--combo-box-bg-color);
    border: 0.1rem solid transparent;
}

.combo-box.light {
    color: var(--light-combo-box-color);
    background: var(--app-gainsboro);
    border: none;
}

.combo-box.selected {
    background: var(--combo-box-selected-bg-color);
    color: var(--combo-box-selected-color);
}

.combo-box.light.selected {
    background: var(--app-gainsboro);
    color: var(--app-gainsboro);
}

.combo-box:hover {
    color: var(--combo-box-hover-color);
    background: var(--combo-box-hover-bg-color);
    border-color: var(--combo-box-border-color);
}

.combo-box.light:hover {
    color: var(--light-combo-box-hover-color);
    background: var(--light-combo-box-hover-bg-color);
}

/*.combo-box:focus {*/
/*    color: var(--combo-box-hover-color);*/
/*    background: var(--combo-box-hover-bg-color);*/
/*}*/

.combo-box.dirty {
    font-weight: 600;
}

.combo-box.disabled,
.combo-box.disabled:hover {
    cursor: auto;
    color: var(--combo-box-disabled-color);
    background: var(--combo-box-disabled-bg-color);
    border: transparent;
}

.list-cell {
    height: 100%;
    padding: 0 0.7rem;
}

.list-cell.selected {
    color: var(--light-combo-box-list-selected-color);
}

ul.list-items {
    background: var(--combo-box-list-bg-color);
    border-top: unset;
    color: var(--combo-box-list-color);
    z-index: 9999;
    max-height: 25rem;
    overflow: auto;
}

ul.list-items.light {
    /*margin-top: 1rem;*/
    background: var(--light-combo-box-list-bg-color);
    color: var(--light-combo-box-list-color);
}

.divider {
    background-color: var(--light-combo-box-list-bg-color);
}

.divider > hr {
    color: var(--combo-box-list-divider-border-color);
    background-color: var(--combo-box-list-divider-border-color);
    border-width: 0;
    height: 0.1rem;
    width: 80%;
}

.list-items > div:not(:first-child){
}

.list-items > .list-cell {
    height: 3rem;
    align-items: center;
}

.list-items > .list-cell .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.list-items > div:hover {
    color: var(--combo-box-list-hover-color);
    background: var(--combo-box-list-hover-bg-color);
}

.list-items > .list-cell.readonly:hover {
    color: var(--light-combo-box-list-color);
    background: var(--light-combo-box-list-bg-color);
}
.combo-box .combo-box-arrow-container {
    background: var(--combo-box-arrow-bg-color);
}


.combo-box .combo-box-arrow {
    fill: var(--combo-box-arrow-color);
    animation: rotationCounterClockwise 0.25s 1 linear;
    transform: rotate(0deg);
}

.combo-box.light .combo-box-arrow-container,
.combo-box.light:hover .combo-box-arrow-container,
.combo-box.selected.light .combo-box-arrow-container {
    background: var(--light-combo-box-arrow-bkg-color);
    opacity: 0.7;
}

.combo-box.light .combo-box-arrow {
    fill: var(--light-combo-box-arrow-color);
}

.combo-box:hover .combo-box-arrow-container {
    background: var(--combo-box-arrow-hover-bg-color);
    border: 0.05rem solid var(--combo-box-arrow-hover-border-color);
}

/*.combo-box:focus .combo-box-arrow, */
.combo-box:hover .combo-box-arrow {
    fill: var(--combo-box-arrow-hover-color);
}

.combo-box.disabled .combo-box-arrow-container,
.combo-box.disabled:hover .combo-box-arrow-container {
    background: var(--light-combo-box-arrow-bkg-color);
    opacity: 0.7;

}

.combo-box.disabled .combo-box-arrow,
.combo-box.disabled:hover .combo-box-arrow {
    fill: var(--combo-box-arrow-color);
}

.combo-box.light:hover .combo-box-arrow {
    fill: var(--light-combo-box-arrow-hover-color);
}


/* Arrow Drop Down Active */
.combo-box.light.selected .combo-box-arrow {
    background: transparent;
    fill: var(--light-combo-box-arrow-color);
}

.combo-box.selected .combo-box-arrow-container {
    background: var(--combo-box-arrow-selected-bg-color);
}

.combo-box.selected .combo-box-arrow {
    fill: var(--combo-box-arrow-selected-color);
    animation: rotationClockwise 0.25s 1 linear;
    transform: rotate(180deg);
}

.combo-box.dirty .combo-box-arrow {

}

ul.list-items::-webkit-scrollbar {
    height: 0.8rem;
    width: 0.4rem;
}

ul.list-items::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: var(--scrollbar-thumb-color);
}

ul.list-items::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background-color: var(--scrollbar-track-color);
}

.clear-button {
    min-width: 6.1rem;
    max-width: 6.1rem;
    z-index: 9999;
}

@keyframes rotationClockwise {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@keyframes rotationCounterClockwise {
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.combo-box.light.disabled {
    background-color: var(--app-gainsboro)
}

