.loader-container {
    /*background: rgba(0, 0, 0, 0.25);*/
    pointer-events: none;
    margin-top: 0 !important;
}

.loader-container .loader,
.loader-container .loader:after {
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
}

.loader-container.nano .loader,
.loader-container.nano .loader:after {
    width: 2rem;
    height: 2rem;
}

.loader-container.small .loader,
.loader-container.small .loader:after {
    width: 5rem;
    height: 5rem;
}

.loader-container.large .loader,
.loader-container.large .loader:after {
    width: 16rem;
    height: 16rem;
}

.loader-container .loader {
    font-size: 1rem;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1rem solid var(--loading-indicator-background-color);
    border-right: 1.1rem solid var(--loading-indicator-background-color);
    border-bottom: 1.1rem solid var(--loading-indicator-background-color);
    border-left: 1.1rem solid var(--loading-indicator-border-color);
    animation: load8 1.1s infinite linear;
}

.loader-container.nano .loader {
    border-top: 0.3rem solid var(--loading-indicator-background-color);
    border-right: 0.3rem solid var(--loading-indicator-background-color);
    border-bottom: 0.3rem solid var(--loading-indicator-background-color);
    border-left: 0.3rem solid var(--loading-indicator-border-color);
}

.loader-container.large .loader {
    border-top: 2.2rem solid var(--loading-indicator-background-color);
    border-right: 2.2rem solid var(--loading-indicator-background-color);
    border-bottom: 2.2rem solid var(--loading-indicator-background-color);
    border-left: 2.2rem solid var(--loading-indicator-border-color);
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
