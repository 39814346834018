@import "framework.visual/theme/stylesheets/index.css";
@import "app.visual/theme/stylesheets/index.css";

html {
  font-size: 8px;
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;
  min-width: 1400px;
  min-height: 600px;
}

body {
  height: 100%;
}

/* https://css-tricks.com/snippets/css/retina-display-media-query/ */
/* Some Users are on 4K displays. The above site handles high res*/

@media (min-width:1475px) {
  html {
    font-size: 9px;
  }
}

@media (min-width:1600px) {
  html {
    font-size: 10px;
  }
}

@media (min-width:2000px) {
  html {
    font-size: 11px;
    min-height: 1000px;
  }
}

#root {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
