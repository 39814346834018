.upload-panel {
}

.upload-panel .pending-item .card .card-header .pending-item-body {

    background: var(--pending-item-bg-color);
    border: 0;
    border-bottom: 4px solid var(--pending-item-border-color);
    color: var(--pending-item-color);
}

.upload-panel .pending-item .card:hover .card-header  .pending-item-body{
    border-bottom: 4px solid var(--pending-item-hover-border-color);
    background: var(--pending-item-hover-bg-color);
    color: var(--pending-item-hover-color);
}

.upload-panel .pending-item .card.loading:hover .card-header .pending-item-body,
.upload-panel .pending-item .card.loading  .card-header .pending-item-body {
    border-bottom: 4px solid var(--pending-item-loading-border-color);
    background: var(--pending-item-loading-bg-color);
    color: var(--pending-item-loading-color);
}

.upload-panel .pending-item .card .pending-item-container {
    background: var(--pending-item-selected-bg-secondary-color);
}

.upload-panel .pending-item .card.selected .card-header .pending-item-body {
    border-bottom: 4px solid var(--pending-item-selected-border-color);
    background: var(--pending-item-selected-bg-color);
    color: var(--pending-item-selected-color);
}

.upload-panel .pending-item .card.disabled .card-header .pending-item-body {
    border-bottom: 4px solid var(--pending-item-disabled-border-color);
    background: var(--pending-item-disabled-bg-color);
    color: var(--pending-item-disabled-color);
}

.upload-panel .pending-item .card.deleted .card-header .pending-item-body {
    cursor: auto;
    border-bottom: 4px solid var(--pending-item-deleted-border-color);
    background: var(--pending-item-deleted-bg-color);
    color: var(--pending-item-deleted-color);
}

.upload-panel .pending-item .card .card-body.expanded {
    min-height: 0;
}

.upload-panel .pending-item .card .pending-item-body .loader-container {
    background: transparent;
    justify-content: end !important;
}

.upload-panel .pending-item .card .pending-item-body .loading-button {
    opacity: 0;
    fill: var(--pending-item-loading-button-color);
}

.upload-panel .pending-item .card:hover .pending-item-body .loading-button {
    opacity: 1;
}

.upload-panel .pending-item .card:hover .pending-item-body .loading-button:hover {
    transform: scale(1.235);
    fill: var(--pending-item-button-hover-bg-color);
}

.upload-panel .pending-item .card:hover .pending-item-body .loading-button.disabled,
.upload-panel .pending-item .card:hover .pending-item-body .loading-button.disabled:hover {
    fill: transparent;
}

.upload-panel .pending-item .card.loading:hover .pending-item-body .button:hover {
    transform: scale(1.235);
    fill: var(--pending-item-button-hover-bg-color);
}

.upload-panel .pending-item .card .accept {
    background: var(--pending-item-button-bg-color);
    fill: var(--pending-item-accept-button-color);
}

.upload-panel .pending-item .card .reject {
    background: var(--pending-item-button-bg-color);
    fill: var(--pending-item-reject-button-color);
}

.upload-panel .pending-item .card .accept:hover {
    transform: scale(1.235);
    fill: var(--pending-item-button-hover-bg-color);
}

.upload-panel .pending-item .card .reject:hover {
    transform: scale(1.235);
    fill: var(--pending-item-button-hover-bg-color);
}

.upload-panel .pending-item .card.deleted:hover .card-header .pending-item-body,
.upload-panel .pending-item .card.deleted  .card-header .pending-item-body {
    border-bottom: 4px solid var(--pending-item-deleted-border-color);
    background: var(--pending-item-deleted-bg-color);
    color: var(--pending-item-deleted-color);
}
