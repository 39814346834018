.system-menu {

}

.system-menu .popup.title-popup .popup-content,
.system-menu .popup.note-popup .popup-content {
    min-width: 29.2rem;
}

.system-menu .report-popup .popup-content {
    width: 40.4rem;
    overflow: hidden;
}

.system-menu .report-popup .template-item-container {
    width: 35.4rem;
    overflow: auto;
}

.system-menu .report-popup .template-item-container .template-item {
    width: 8rem;
    height: 10.8rem;
    opacity: 0.3;
}

.system-menu .report-popup .template-item-container .template-item.selected {
    opacity: 1;
}

.search-results-portal {
    transform: translateY(0%) translateX(100%);
    min-width: 17rem;
}
