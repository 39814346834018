.system-tool-panel {
    background: var(--system-tool-panel-background);
}

.system-tool-panel .header {
    padding: 1.0rem;
    background: var(--system-tool-header-background);
    color: var(--system-tool-header-color);
    font-size: 1.6rem;
}

