.settings-panel {
 top: 0;
 right: 0;
 /*width: 32rem;*/
 background: var(--settings-panel-bg-color);
 /*border: 1px solid var(--settings-panel-border-color);*/
 opacity: 1;
}

.settings-panel .name .rounded-circle {
 width: 4.7rem;
 height: 4.8rem;
 color: var(--settings-panel-header-icon-color);
 background: var(--settings-panel-header-icon-bg-color);
}

.settings-panel .name .namerole {
 width: 100%;
 /*width: 26.1rem;*/
 height: 3.2rem;
 background-color: var(--settings-panel-section-header-bg-color);
}

.settings-panel .name .displayname {
 color: var(--settings-panel-header-name-color);
}

.settings-panel .name .role {
 color: var(--settings-panel-header-role-color);
}

.settings-panel .profile-info-grid {
 display: grid;
 grid-template-rows: repeat(8, 3.5rem);
 grid-template-columns: 12rem 15rem;
 grid-gap: 0 1.7rem;
 grid-auto-flow: column;
 background-color: var(--settings-panel-userinfo-bg-color);
}

.settings-panel .profile-info-grid .fields,
.settings-panel .customizations-grid .fields {
 color: var(--settings-panel-userinfo-fields-text-color);
}

.settings-panel .profile-info-grid .info > * {
 color: var(--settings-panel-userinfo-info-text-color) !important;
}

.settings-panel .section-header {
 height: 3.5rem;
 background-color: var(--settings-panel-section-header-bg-color);
 color: var(--settings-panel-header-name-color);
}

.settings-panel .section-header .settings-svg {
 fill: var(--settings-panel-svg-grey);
}

.settings-panel .header-1 {
 color: var(--settings-panel-header-name-color);
}

.settings-panel .customizations-grid {
 display: grid;
 grid-template-rows: repeat(3, 3.5rem);
 grid-template-columns: 12rem 15rem;
 grid-gap: 0 2.1rem;
 grid-auto-flow: column;
}

.settings-panel .section-header .rounded-circle {
 width: 2.1rem;
 height: 2.1rem;
 color: var(--settings-panel-section-header-bg-color);
 background: var(--settings-panel-svg-grey);
}

.settings-panel .settings-button {
 cursor: pointer;
}

.settings-panel .logout-button {
 border: 1px solid #856C6C;
 cursor: pointer;
 fill: var(--settings-panel-logout-pink);
 color: var(--settings-panel-logout-pink);
}

/* Styling for combo box button */

.settings-panel .combo-box-button {
 min-width: 7rem;
 background: var(--light-combo-box-bg-color);
 color: var(--light-combo-box-color);
 min-height: 2rem;
}

.settings-panel .combo-box-button:hover {
 color: var(--light-combo-box-hover-color);
 background: var(--light-combo-box-hover-bg-color);
}

.settings-panel .combo-box-button.selected {
 color: var(--light-combo-box-selected-color);
 background: var(--light-combo-box-selected-bg-color);
}
