.tags-panel {

}

.tags-panel .letter-header {
    font-size: 4.8rem;
    line-height: 4.8rem;
    background: var(--tags-panel-header-background);
    color: var(--tags-panel-header-color);
    width: 7.5rem;
}

.tags-panel .tag {
    height: 2.55rem;
    background: transparent;
    color: var(--tags-panel-tag-color);
}

.tags-panel .tag:hover,
.tags-panel .tag.selected {
    background: var(--tag-bg-color);
    color: var(--tag-color);
}

.tags-panel .tag-grid {
    display: grid;
    grid-template-rows: repeat(auto-fill, 2.55rem);
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0 1rem;
}
