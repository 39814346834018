.document-panel {
    display: flex;
    flex-direction: column;
    background: var(--old-document-panel-bg-color);
    color: var(--old-document-panel-color);

    --label-size: 7.5rem;
}

.document-panel .header {
    color: var(--old-document-panel-secondary-color);
}

.document-panel .title {
    color: var(--old-document-panel-color);
}

.document-panel .header .disabled {
    pointer-events: none;
    opacity: 0.5;
}

.document-panel .body {
    background: var(--old-document-panel-pdf-bg-color);
}

.document-panel .rpv-default-layout__sidebar-tabs {
    width: 0;
    border: 0;
}

.document-panel .text-field {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 10rem;
}

.document-panel .combo-box {
    min-width: 17rem;
}

.document-panel .property-grid-container {
    min-height: 14.5rem;
    max-height: 14.5rem;
    transition: all 300ms ease-out;
    align-content: start;
}

.document-panel .property-grid-container.expanded {
    min-height: 33rem;
    max-height: 33rem;
    transition: all 300ms ease-out;
}

.document-panel .title-grid {
    display: grid;
    grid-auto-rows: 3.5rem;
    grid-template-columns: var(--label-size) 1fr;
    grid-auto-flow: row;
    grid-gap: 0 2rem;
}

.document-panel .property-grid {
    display: grid;
    grid-template-rows: repeat(auto-fit, 3.5rem);
    grid-template-columns: repeat(2, var(--label-size) 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 0 2rem;
}

.document-panel .info-grid {
    display: grid;
    grid-auto-rows: 3.5rem;
    grid-template-columns: var(--label-size) 1fr repeat(1, auto 1fr);
    grid-auto-flow: row;
    grid-gap: 0 2rem;
}

.document-panel .sme-grid {
    display: grid;
    grid-template-columns: var(--label-size) 1fr repeat(2, auto 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 0 2rem;
}

.document-panel .card .card-body.expanded {
    min-height: 25rem;
}

.document-panel .info-button {
    background: transparent;
    color: var(--search-results-list-info-color);
    fill: var(--search-results-list-info-color);
}

.document-panel .card.selected .info-button {
    color: var(--search-results-list-selected-info-color);
    fill: var(--search-results-list-selected-info-color);
}

.document-panel .pdf-viewer *,
.document-panel .pdf-viewer *:before,
.document-panel .pdf-viewer *:after{
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;

}

.document-panel .pdf-viewer .toolbar .button {
    background: transparent;
}

.document-panel .pdf-viewer .pdf-icon {
    fill: var(--old-document-panel-pdf-icon-color);
}

.document-panel .pdf-viewer .pagination {
    fill: var(--old-document-panel-pdf-icon-secondary-color);
    color: var(--old-document-panel-pdf-color);
    font-size: 1.4rem;
}

.document-panel .tag-button,
.tags-portal .tag-button {
    background: var(--old-document-panel-button-bg-color);
    height: 1.7rem;
    min-width: 1.7rem !important;
    padding: 0 !important;
}

.document-panel .button {
    min-width: 8.7rem;
}

.document-panel .custom-v-gap > *:not(:first-child):not(.exclude-item) {
    margin-top: 1.7rem;
}

.tags-portal {
    margin-top: -2.55rem;
    width: 50rem;
}

.tags-portal .d-inline-flex {
    gap: 1rem 0;
}

.tags-portal .close {
    top: -1.75rem;
    right: 1rem;
}

.document-panel .close .button {
    min-width: 4rem;
    min-height: 4rem;
}

.document-panel .ellipsis-button {
    min-width: 3.2rem;
    height: 2rem;
}

.document-panel .ellipsis-button.invisible {
    background: transparent;
}

.document-panel .ellipsis-button.invisible svg {
    fill: transparent;
}

.document-panel .text-right {
    max-width: 11rem;
}

.document-panel .nlp-loader {
    background: transparent;
    width: auto !important;
    justify-content: start !important;
}

.document-panel .popup {
    width: 33.3rem;
    height: 20.7rem;
    color: var(--old-document-panel-context-menu-color);
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
}

.document-panel .popup .popup-content {
    padding: 0 !important;
}

.document-panel .popup .text-area {
    min-width: 31.4rem;
    min-height: 8.1rem;
}

.document-panel .popup .text-area textarea {
    background: var(--old-documnent-panel-context-menu-text-area-background);
    color: var(--old-document-panel-context-menu-text-area-color);
    border: none;
    font-style: italic;
}

.document-panel .popup .combo-box {
    width: 13.8rem;
}

.document-panel .note {
    right: 0;
    top: 75%;
    z-index: 1;
}

.document-panel .note .button {
    fill: var(--old-document-panel-highlight-button-fill);
    background: var(--old-document-panel-highlight-button-background);
    border: 0.05rem solid var(--old-document-panel-highlight-button-border-color);
    min-width: 2.7rem;
}

.document-panel .popup .close {
    top: 1rem;
    right: 1rem;
}

.document-panel .popup .close .button {
    min-width: 1.7rem;
    height: 1.7rem;
}

.document-panel .popup .close .button svg {
    fill: var(--popup-color);
}

.document-panel .required-field {
    color: var(--old-document-panel-required-field-color);
}

.tooltip-portal.top {
    margin-top: -11.5rem;
    margin-left: -7.8rem;
}

.tooltip-portal.top.portal::after {
    left: 12rem
}
