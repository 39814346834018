.check-box {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 1.5rem;
    width: 1.5rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    border: 1px solid var(--check-box-border-color);
    border-radius: 1px;
}

.check-box.light {
    border: 1px solid var(--check-box-light-border-color);
}

.check-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.check-box .checkmark {
    display: none;
    position: absolute;
    top: -1.1rem;
    left: -0.3rem;
    background-color: transparent;
    fill: var(--check-box-checkmark-color);
}

.check-box.light .checkmark {
    fill: var(--check-box-light-checkmark-color);
}

.check-box.selected {
    border: 1px solid var(--check-box-selected-border-color);
}

.check-box.selected .checkmark {
    display: block;
}

.check-box.disabled {
    border-color: var(--check-box-disabled-border-color);
}

.check-box:hover {
    border-color: var(--check-box-hover-border-color);
}
