.auto-complete {

}

.auto-complete .auto-complete-container {
    background: var(--auto-complete-bg-color);
}

.auto-complete .match {
    color: var(--auto-complete-color);
}
