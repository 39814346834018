.pocket-manager-panel {
    background: var(--pocket-manager-panel-bg-color);
    /*border: 0.1rem solid var(--pocket-manager-panel-border-color);*/
    height: calc(100% - 5.9rem);
}

.pocket-manager-panel .header {
    background: var(--pocket-manager-panel-header-bg-color);
}

.pocket-manager-panel .header .icon {
    fill: var(--pocket-manager-panel-header-icon-fill);
}

.pocket-manager-panel .section-header {
    background: var(--pocket-manager-panel-section-header-bg-color);
    width: 42.8rem;
    top: -3rem;
}

.pocket-manager-panel .pocket-content-grid {
    display: grid;
    grid-template-rows: repeat(auto-fill, 10.2rem);
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 0.5rem 3.5rem;
}

.pocket-manager-panel .pocket {
    color: var(--pocket-manager-panel-grid-item-color);
    fill: var(--pocket-manager-panel-grid-item-fill);
}

.pocket-manager-panel .pocket .title,
.pocket-manager-panel .selected-pocket .title .text-info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: var(--pocket-manager-panel-pocket-color);
}

.pocket-manager-panel .pocket .author {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.pocket-manager-panel .pocket.selected {
    fill: var(--pocket-manager-panel-grid-item-selected-fill);
}

.pocket-manager-panel .pocket.selected .title {
    color: var(--pocket-manager-panel-grid-item-selected-color);
}

.pocket-manager-panel .popup.share-popup .popup-content {
    min-width: 40.4rem;
}

.pocket-manager-panel .popup.title-popup .popup-content,
.pocket-manager-panel .popup.note-popup .popup-content {
    min-width: 29.2rem;
}

.auto-complete-container {
    margin-left: 0 !important;
}

.pocket-manager-panel .author-icon,
.author-tooltip .author-icon {
    width: 3rem;
    height: 3rem;
    color: var(--pocket-manager-panel-author-icon-color);
    background: var(--pocket-manager-panel-author-icon-bg-color);
}

.pocket-manager-panel .author-icon.self,
.author-tooltip .author-icon.self {
    border: 0.15rem solid var(--pocket-manager-panel-author-icon-border-color);
}

.pocket-manager-panel .selected-pocket .author-icon.self,
.author-tooltip .author-icon.self {
    border: 0.15rem solid var(--pocket-manager-panel-selected-pocket-author-icon-border-color);
    color: var(--pocket-manager-panel-selected-pocket-author-icon-color) !important;
}

.pocket-manager-panel .author-icon.editor,
.author-tooltip .author-icon.editor {
    background: #D86C34;
}

.pocket-manager-panel .loader-container {
    width: auto !important;
}

.pocket-manager-panel .report-popup .popup-content {
    width: 40.4rem;
    overflow: hidden;
}

.pocket-manager-panel .report-popup .template-item-container {
    width: 35.4rem;
    overflow: auto;
}

.pocket-manager-panel .report-popup .template-item-container .template-item {
    min-width: 8rem;
    min-height: 10.8rem;
    opacity: 0.3;
}

.pocket-manager-panel .report-popup .template-item-container .template-item.selected {
    opacity: 1;
}

.pocket-manager-panel .pocket .collaboration-info,
.pocket-manager-panel .selected-pocket .collaboration-info {
    background: var(--pocket-manager-panel-collaboration-info-bg-color);
    width: fit-content;
}

.pocket-manager-panel .pocket .icon,
.pocket-manager-panel .selected-pocket .icon {
    min-width: 0.8rem;
    height: 0.8rem;
}
.pocket-manager-panel .pocket .icon.available,
.pocket-manager-panel .selected-pocket .icon.available {
    background: var(--pocket-manager-panel-icon-available-bg-color);
}

.pocket-manager-panel .pocket .icon.in-use,
.pocket-manager-panel .selected-pocket .icon.in-use {
    background: var(--pocket-manager-panel-icon-in-use-bg-color);
}

.pocket-manager-panel .selected-pocket .header {
    /*background: var(--pocket-manager-panel-section-header-bg-color);*/
    background: inherit;
    width: fit-content;
}

.pocket-manager-panel .selected-pocket .return {
    width: fit-content;
}

.pocket-manager-panel .selected-pocket .header .title {
    background: var(--pocket-manager-panel-section-header-title-bg-color);
    border: 0.05rem solid var(--pocket-manager-panel-section-header-title-border-color);
}

.pocket-manager-panel .selected-pocket .header .orange {
    color: #D86C34;
}

.pocket-manager-panel .pocket-content-item-portal {
    background: var(--pocket-manager-panel-grid-item-selected-bg-color);
    width: calc(50% - 3.3rem);
}

.pocket-manager-panel .pocket-content-item-portal .pocket-content-item-portal {
    left: 0;
    width: calc(100% - 2.0rem);
}

.pocket-manager-panel .pocket-content-item {
    min-height: 10.2rem;
}

.pocket-manager-panel .pocket-content-item .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.pocket-manager-panel .pocket-content-item .excerpt-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.pocket-manager-panel .pocket-content-item .author {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.pocket-manager-panel .pocket-content-item.selected {
    background: var(--pocket-manager-panel-grid-item-selected-bg-color);
}
