.user-input {

}

.user-input .button,
.user-input .button:hover {
    color: var(--user-input-button-color);
    width: 3rem;
    height: 3rem;
    z-index: 1;
}

.user-input.expanded {
}

.user-input.expanded .button {
    color: var(--user-input-expanded-button-color);
    background: var(--user-input-expanded-button-bg-color);
}

.user-input.expanded .text-edit-container {
    height: 3rem;
    background: var(--user-input-expanded-text-edit-bg-color);
    position: absolute;
    padding-left: 3rem;
    padding-right: 1.2rem;
    z-index: 0;
    border-radius: 1.6rem;
}

.user-input.expanded .text-edit-container .text-edit {
    min-height: 3rem;
}

.user-input.expanded .text-edit-container .text-edit input {
    border: none;
}
