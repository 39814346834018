.system-banner {
    background: var(--system-banner-background);
    /*width: 100vw;*/
}

.system-banner .title {
    color: var(--system-banner-color);
}

.system-banner .text {
    color: var(--system-banner-secondary-color);
}

.system-banner .system-banner-text-accent {
    color: var(--system-banner-accent-color);
}

.system-banner .button {
    min-width: 8.7rem;
}

.system-banner .help-button {
    min-width: 2.6rem;
    min-height: 2.6rem;
    font-size: 2rem;
    font-weight: bold;
    color: var(--app-black);
    background-color: var(--app-whiteSmoke);
}

.system-banner .help-button:hover {
    color: var(--app-white);
    background-color: var(--app-bunker);
}

.system-banner .help-button.selected {
    color: var(--app-black);
    background-color: var(--app-white);
}

.system-banner .logout-button {
    /*border: 1px solid #856C6C;*/
    cursor: pointer;
    fill: var(--settings-panel-logout-pink);
    color: var(--settings-panel-logout-pink);
}

@media screen and (max-width: 800px) {
    .system-banner .hidden-first {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .system-banner .hidden-second {
        display: none;
    }
}
