.search-results-panel {
    background-color: var(--search-results-panel-bg-color);
}

.search-results-panel .button {
    background: var(--light-toggle-button-bg-color);
    fill: var(--light-toggle-button-color);
    color: var(--light-toggle-button-color);
    /*min-width: 3.3rem;*/
}

.search-results-panel .button:hover {
    background: var(--light-toggle-button-hover-bg-color);
    color: var(--light-toggle-button-hover-color);
    fill: var(--light-toggle-button-hover-color);
}

.search-results-panel .button.selected,
.search-results-panel .button.selected:hover {
    background: var(--light-toggle-button-selected-bg-color);
    fill: var(--light-toggle-button-selected-color);
    color: var(--light-toggle-button-selected-color)
}

.search-results-panel .combo-box svg {
    fill: var(--light-combo-box-arrow-color);
}

.search-results-panel .combo-box:hover svg,
.search-results-panel .combo-box.selected:hover svg {
    fill: var(--light-combo-box-arrow-hover-color)
}

.search-results-panel .combo-box.selected svg {
    fill: var(--light-combo-box-arrow-selected-bg-color);
}

.search-results-panel .combo-box {
    background: var(--light-toggle-button-bg-color);
}

/* Card */
.search-results-panel .search-results .cards {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(33.7rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(11.4rem, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 1.5rem 7rem;
}

.search-results-panel .search-results .cards .result-item .card {
    background: var(--search-results-card-bg-color);
    border: 0.1rem solid transparent;
}

.search-results-panel .search-results .cards .result-item .card:hover {
    background: var(--search-results-card-hover-bg-color);
}

.search-results-panel .search-results .cards .result-item .card .title {
    color: var(--search-results-card-accent-color);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.search-results-panel .search-results .cards .result-item .card .title-icon {
    fill: var(--search-results-card-accent-color) !important;
}

.search-results-panel .search-results .cards .result-item .card:hover .title {
    color: var(--search-results-card-hover-accent-color);
}

.search-results-panel .search-results .cards .result-item .card:hover .title-icon {
    fill: var(--search-results-card-hover-accent-color) !important;
}

.search-results-panel .search-results .cards .result-item .card .text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: var(--search-results-card-color);
}

.search-results-panel .search-results .cards .result-item .card:hover .text {
    color: var(--search-results-card-hover-color);
}

.search-results-panel .search-results .cards .result-item .card .datetime {
    min-width: 8rem;
}

.search-results-panel .search-results .cards .result-item .card .text-info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.search-results-panel .search-results .cards .result-item.selected .card {
    background: var(--search-results-card-selected-bg-color);
    border: 0.1rem solid var(--search-results-card-selected-border-color);
}

.search-results-panel .search-results .cards .result-item.selected .card .title {
    color: var(--search-results-card-selected-accent-color);
    font-weight: bold;
}

.search-results-panel .search-results .cards .result-item.selected .card .title-icon {
    fill: var(--search-results-card-selected-accent-color) !important;
}

.search-results-panel .search-results .cards .result-item.selected .card .text {
    color: var(--search-results-card-selected-color);
}

/* List */
.search-results-panel .search-results .list .result-item .title {
    font-size: 2.0rem;
    line-height: 2.0rem;
    color: var(--search-results-list-accent-color);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.search-results-panel .search-results .list .result-item .title-icon {
    fill: var(--search-results-list-accent-color) !important;
}

.search-results-panel .search-results .list .result-item:hover .title {
    color: var(--search-results-list-hover-accent-color);
}

.search-results-panel .search-results .list .result-item:hover .title-icon {
    fill: var(--search-results-list-hover-accent-color) !important;
}

.search-results-panel .search-results .list .result-item.selected .title {
    color: var(--search-results-list-selected-accent-color);
}

.search-results-panel .search-results .list .result-item.selected .title-icon {
    fill: var(--search-results-list-selected-accent-color) !important;
}

.search-results-panel .search-results .list .result-item .text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: var(--search-results-list-color);
}

.search-results-panel .search-results .list .result-item .text.collapsed {
    -webkit-line-clamp: 2;
}

.search-results-panel .search-results .list .result-item:hover .text {
    color: var(--search-results-list-hover-color);
}

.search-results-panel .search-results .list .result-item.selected .text {
    color: var(--search-results-list-selected-color);
}

.search-results .search-results .list .result-item {
    background: var(--search-results-list-bg-color);
}

.search-results-panel .search-results .list .result-item:hover {
    background: var(--search-results-list-hover-bg-color);
}

.search-results-panel .search-results .list .result-item.selected {
    background: var(--search-results-list-selected-bg-color);
}

.search-results-panel .search-results .list .result-item .card .info-button {
    background: transparent;
    color: var(--search-results-list-info-color);
    fill: var(--search-results-list-info-color);
}

.search-results-panel .search-results .list .result-item .card.selected .info-button {
    color: var(--search-results-list-selected-info-color);
    fill: var(--search-results-list-selected-info-color);
}

.search-results-panel .search-results .list .result-item .card {
    color: var(--search-results-list-info-accent-color);
}

.search-results-panel .search-results .list .result-item .card .custom-v-gap > *:not(:first-child):not(.exclude-item) {
    margin-top: 1.87rem;
}

.search-results-panel .search-results .list .result-item .card .card-body.expanded {
    min-height: 40rem;
}

.search-results-panel .search-results .list .result-item .card.collapsed .card-body.expanded {
    min-height: 25rem;
}

.search-results-panel .search-results .list .result-item .card .property-grid {
    display: grid;
    grid-template-rows: repeat(11, 3.5rem);
    grid-template-columns: 10rem 15rem;
    grid-auto-flow: column;
    grid-gap: 0 3rem;
}

.search-results-panel .search-results .list .result-item .card.collapsed .property-grid {
    grid-template-rows: repeat(7, 3.5rem);
}

.search-results-panel .search-results .list .result-item .card .property-grid-2 {
    display: grid;
    grid-template-rows: repeat(2, 3.5rem);
    grid-template-columns: 5rem 15rem;
    grid-auto-flow: column;
    grid-gap: 0 3rem;
}

.search-results-panel .search-results .list .result-item .card .custom-margin {
    margin-top: 3.45rem;
}

/* Table */
.search-results-panel .table .result-item {
    height: 7.6rem;
    color: var(--search-results-table-color);
    padding: 0;
}

.search-results-panel .table .result-item .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.search-results-panel .table .result-item .author {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.search-results-panel .table .result-item.selected {
    background: var(--search-results-table-selected-bg-color);
    color: var(--search-results-table-selected-color);
}

.search-results-panel .table .result-item:hover {
    background: var(--search-results-table-hover-bg-color);
    color: var(--search-results-table-hover-color);
}

.search-results .table .table-header .column-header {
    background: var(--search-results-table-header-bg-color);
    color: var(--search-results-table-header-color);
    height: 2.7rem;
}

.search-results-panel .loader {
    background: transparent;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.search-results-panel .banner {

}

.search-results-panel .banner .text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.search-results-panel .banner .button {
    fill: var(--primary-color)
}

.search-results-portal {
    transform: translateY(calc(-100% - 40px));
    min-width: 13.8rem;
}

.search-results-panel .selected-pocket .header {
    /*background: var(--pocket-manager-panel-section-header-bg-color);*/
    background: inherit;
    width: fit-content;
}

.search-results-panel .selected-pocket .return {
    width: fit-content;
}

.search-results-panel .selected-pocket .header .title {
    background: var(--pocket-manager-panel-section-header-title-bg-color);
    border: 0.05rem solid var(--pocket-manager-panel-section-header-title-border-color);
}

.search-results-panel .selected-pocket .header .orange {
    color: #D86C34;
}

.search-results-panel .author-icon,
.author-tooltip .author-icon {
    width: 3rem;
    height: 3rem;
    color: var(--pocket-manager-panel-author-icon-color);
    background: var(--pocket-manager-panel-author-icon-bg-color);
}

.search-results-panel .author-icon.self,
.author-tooltip .author-icon.self {
    border: 0.15rem solid var(--pocket-manager-panel-author-icon-border-color);
}

.search-results-panel .selected-pocket .author-icon.self,
.author-tooltip .author-icon.self {
    border: 0.15rem solid var(--pocket-manager-panel-selected-pocket-author-icon-border-color);
    color: var(--pocket-manager-panel-selected-pocket-author-icon-color) !important;
}

.search-results-panel .author-icon .editor,
.author-tooltip .author-icon.editor {
    background: #D86C34;
}

.search-results-panel .selected-pocket .pocket-content-item-portal {
    background: var(--pocket-manager-panel-grid-item-selected-bg-color);
    width: calc(50% - 3.3rem);
}

.search-results-panel .selected-pocket .pocket-content-item-portal .pocket-content-item-portal {
    left: 0;
    width: calc(100% - 2.0rem);
}

.search-results-panel .selected-pocket .pocket-content-item {
    min-height: 10.2rem;
}

.search-results-panel .selected-pocket .pocket-content-item .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.search-results-panel .selected-pocket .pocket-content-item .author {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.search-results-panel .selected-pocket .pocket-content-item.selected {
    background: var(--pocket-manager-panel-grid-item-selected-bg-color);
}

.search-results-panel .pocket-content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48.2em, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(13.5rem, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 0.5rem 3.5rem;
}

.search-results-panel .card .collaboration-info,
.search-results-panel .selected-pocket .collaboration-info {
    background: var(--app-gainsboro);
    width: fit-content;
}

.search-results-panel .card .icon {
    min-width: 0.8rem;
    height: 0.8rem;
}

.search-results-panel .card .icon.available {
    background: #48A8FF;
}

.search-results-panel .card .icon.in-use {
    background: #D86C34;
}

.search-results-panel .pocket-content-item .excerpt-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}
