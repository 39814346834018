.report-panel {
    background: var(--report-panel-bg-color);
    color: var(--report-panel-color);
}

.report-panel .header {
    color: var(--report-panel-secondary-color);
}

.report-panel .title {
    color: var(--report-panel-color);
}

.report-panel .header .disabled {
    pointer-events: none;
    opacity: 0.5;
}

.report-panel .body {
    background: var(--report-panel-preview-bg-color);
}

.report-panel .text-field {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*.report-panel .header .combo-box {*/
/*    min-width: 19.5rem;*/
/*}*/

/*.report-panel .property-grid {*/
/*    display: grid;*/
/*    grid-template-rows: repeat(auto-fit, 3.5rem);*/
/*    grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));*/
/*    grid-auto-rows: 1fr;*/
/*    grid-gap: 0 3rem;*/
/*}*/

/*.report-panel .property-grid .label {*/
/*    min-width: 11rem;*/
/*}*/

/*.report-panel .header .button {*/
/*    min-width: 8.7rem;*/
/*}*/

.report-panel .toolbar .button.selected {
    fill: var(--primary-color);
}

.report-panel .toolbar .combo-box {
    min-width: 15rem;
}

.report-panel .toolbar .combo-box.font-size {
    min-width: 6rem;
}

.report-panel .loader {
    background: transparent;
    justify-content: end !important;
}

.report-panel .edit-popup {
    color: var(--report-panel-popup-color);
}
